import styles from "../styles/404.module.scss";
import { useRouter } from "next/router";
import Spacer from "../components/Spacer";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Header, HeaderVariant } from "../components/Header";
import { Meta } from "../components/Meta";

export default function Custom404() {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <>
      <Meta title={"404"} />

      <main className={clsx(styles.main, "column ai-center")}>
        <Header variant={HeaderVariant.light} />
        <Spacer height={51} />
        <div className={styles.content}>
          <div className={styles.text}>404</div>
          <div className={styles.itsEmptyHere}>{t("404.itsEmptyHere")}</div>
          <div className={styles.message}>{t("404.message")}</div>
          <div
            className={clsx("outlineButton", styles.button)}
            onClick={() => router.push("/")}
          >
            {t("backToHome")}
          </div>
        </div>
      </main>
    </>
  );
}
